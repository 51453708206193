import { useContext } from "react";
import { WizardContext } from "./store/wizard.store";
import { calculateWeeks } from "./utils/WeekUtil";

export const WizardSearchResult: React.VFC<WizardSearchResultProps> = (props) => {
    let { wizardFormData, setWizardFormData } = useContext(WizardContext);

    const onClickSelectTimeSlot = (timeSlot: TimeSlot, performerName: string) => {
        setWizardFormData((prevState) => ({ ...prevState, selectedTimeSlot: timeSlot, performerName: performerName, activePage: prevState.activePage + 1 }));
    }

    const onClickGetMore = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (wizardFormData.isBookingWithCode) {
            getMoreForBookingCode();
        } else {
            getMore();
        }
    }

    const getMore = async (request?: WizardSearchRequest) => {
        if (!request) {
            request = {
                currentPageId: wizardFormData.currentPageId,
                selectedClinics: wizardFormData.selectedClinics.map(x => x.value),
                selectedDate: wizardFormData.selectedDate,
                selectedDateEnd: wizardFormData.selectedDate,
                SelectedTreatmentType: wizardFormData.selectedTreatmentType,
                skip: props.skip + 10,
                newRequest: false
            };
        }

        props.setIsLoading(true);

        let { currentWeekStart, currentWeekEnd, weeks } = calculateWeeks(request);

        let foundTimeSlot = wizardFormData.resultCount;

        for (let i = 0; i < weeks; i++) {
            request.selectedDate = currentWeekStart;
            request.selectedDateEnd = currentWeekEnd;

            for (let selectedClinic of wizardFormData.selectedClinics.map(x => x.value)) {
                request.selectedClinic = selectedClinic;

                let count = await props.getTimes(request);

                foundTimeSlot += count;

                setWizardFormData((prevState) => ({ ...prevState, resultCount: wizardFormData.resultCount + count }));
            }

            if (foundTimeSlot >= 10) break;

            currentWeekStart.setDate(currentWeekStart.getDate() + 7);
            currentWeekEnd.setDate(currentWeekStart.getDate() + 6);
        }

        props.setSkip(props.skip + 10);
        props.setIsLoading(false);


        if (foundTimeSlot === 0) {
            setWizardFormData((prevState) => ({ ...prevState, searchResult: null }));
        }
    }

    const getMoreForBookingCode = async (request?: WizardSearchRequest) => {
        if (!request) {
            request = {
                currentPageId: wizardFormData.currentPageId,
                selectedClinics: wizardFormData.selectedClinics.map(x => x.value),
                selectedDate: wizardFormData.selectedDate,
                selectedDateEnd: wizardFormData.selectedDate,
                SelectedTreatmentType: wizardFormData.selectedTreatmentType,
                skip: props.skip + 10,
                bookingCode: wizardFormData.bookingCode.trim(),
                personalNumber: wizardFormData.personalNumber.trim(),
                newRequest: false
            };
        }

        props.setIsLoading(true);

        let { currentWeekStart, currentWeekEnd, weeks } = calculateWeeks(request);

        let foundTimeSlot = wizardFormData.resultCount;

        for (let i = 0; i < weeks; i++) {
            request.selectedDate = currentWeekStart;
            request.selectedDateEnd = currentWeekEnd;

            for (let selectedClinic of wizardFormData.selectedClinics.map(x => x.value)) {
                request.selectedClinic = selectedClinic;

                let count = await props.getTimes(request);

                foundTimeSlot += count;

                setWizardFormData((prevState) => ({ ...prevState, resultCount: wizardFormData.resultCount + count }));
            }

            if (foundTimeSlot >= 10) break;

            currentWeekStart.setDate(currentWeekStart.getDate() + 7);
            currentWeekEnd.setDate(currentWeekStart.getDate() + 6);
        }

        props.setSkip(props.skip + 10);
        props.setIsLoading(false);

        if (foundTimeSlot === 0) {
            setWizardFormData((prevState) => ({ ...prevState, searchResult: null }));
        }
    }

    const displayNameFacility = (timeSlot: TimeSlot) => {
        const foundAdditionalClinicInformation = props.additionalClinicInformation.find(x => x.multiCheckboxItem.checkboxValue == timeSlot.healthcareFacilityId);

        if (foundAdditionalClinicInformation) return foundAdditionalClinicInformation.multiCheckboxItem.checkboxText;
        else return timeSlot.healthcareFacilityName.replace("Folktandvården", "");
    }

    const linkFacility = (timeSlot: TimeSlot) => {
        const foundAdditionalClinicInformation = props.additionalClinicInformation.find(x => x.multiCheckboxItem.checkboxValue == timeSlot.healthcareFacilityId);

        return foundAdditionalClinicInformation.clinicLink;
    }

    return (
        <>
            {props.activateLastMinute && <div className="grid g-col-12 ftvwebbooking__search-results-wrapper--dark">
                <p className="visually-hidden" aria-live="polite">Antal sista minuter tider: 1</p>
                <div className="g-col-12 ftvwebbooking__search-results-wrapper-header">
                    <div dangerouslySetInnerHTML={{ __html: props.lastMinuteResultIcon }}></div>
                    <strong>Sista minuten</strong>
                    <div className="g-col-12" dangerouslySetInnerHTML={{ __html: props.infoTextLastMinuteSearchResult }} />
                </div>
                <div className="grid g-col-12 ftvwebbooking__search-results last-minute">
                    <div className="grid g-col-12 ftvwebbooking__search-results-header">
                        <div className="g-col-2">
                            <strong>Datum</strong>
                        </div>
                        <div className="g-col-2">
                            <strong>Tid</strong>
                        </div>
                        <div className="g-col-3">
                            <strong>Klinik</strong>
                        </div>
                        <div className="g-col-3">
                            <strong>Behandlare</strong>
                        </div>
                    </div>
                    {wizardFormData.lastMinuteSearchResult && Object.values(wizardFormData.lastMinuteSearchResult).some(x => x !== null && x !== '') &&
                        <div className="grid g-col-12">
                            <div className="grid g-col-12 ftvwebbooking__search-result--mobile">
                                <div className="g-col-9">
                                    {new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getDate() + "/" + (new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getMonth() + 1)},
                                    {' ' + new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getHours() + ":" + ('0' + new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getMinutes()).slice(-2)}<br />
                                    {wizardFormData.lastMinuteSearchResult.performerName}<br />
                                    {displayNameFacility(wizardFormData.lastMinuteSearchResult)}
                                </div>
                                <div className="g-col-3">
                                    <button className="button-primary" type="button" onClick={() => onClickSelectTimeSlot(wizardFormData.lastMinuteSearchResult, wizardFormData.lastMinuteSearchResult.performerName)}>Välj</button>
                                </div>
                            </div>
                            <div className="grid g-col-12 ftvwebbooking__search-result last-minute">
                                <div className="g-col-2">
                                    {new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getDate() + "/" + (new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getMonth() + 1)}
                                </div>
                                <div className="g-col-2">
                                    {new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getHours() + ":" + ('0' + new Date(Date.parse(wizardFormData.lastMinuteSearchResult.startTime)).getMinutes()).slice(-2)}
                                </div>
                                <a href={linkFacility(wizardFormData.lastMinuteSearchResult)} className="g-col-3">
                                    {displayNameFacility(wizardFormData.lastMinuteSearchResult)}
                                </a>
                                <div className="g-col-3">
                                    {wizardFormData.lastMinuteSearchResult.performerName}
                                </div>
                                <div className="g-col-2">
                                    <button className="button-primary" type="button" onClick={() => onClickSelectTimeSlot(wizardFormData.lastMinuteSearchResult, wizardFormData.lastMinuteSearchResult.performerName)}>Välj tid</button>
                                </div>
                            </div>
                        </div>
                    }
                    {props.isLoadingLastMinute &&
                        <div className="grid g-col-12 ftvwebbooking__search-results--loading"><span className="loader"></span></div>
                    }
                    {wizardFormData.lastMinuteSearchResult === null &&
                        <div className="grid g-col-12 ftvwebbooking__search-results-wrapper ftvwebbooking__search-results-wrapper--dark">
                            <p className="g-col-12">
                                Tyvärr finns det inga lediga tider. För att få förslag på tider kan du testa att söka på: annat datum, annan tid på dagen, en annan klinik eller ett annat område.
                            </p>
                        </div>
                    }
                </div>
            </div>}

            {!props.errorMessage ? <div className="grid g-col-12 ftvwebbooking__search-results-wrapper">
                <div className="g-col-12 ftvwebbooking__search-results-wrapper-header">
                    <div dangerouslySetInnerHTML={{ __html: props.searchResultIcon }}></div>
                    <strong>Sökträffar</strong>
                    <div className="g-col-12" dangerouslySetInnerHTML={{ __html: props.infoTextSearchResult }} />
                </div>
                <p id="tiderCount" className="visually-hidden" aria-live="polite">Antal tider: {wizardFormData.searchResult && wizardFormData.searchResult.length > 0 && wizardFormData.searchResult.length}</p>
                <ul className="grid g-col-12 ftvwebbooking__search-results">
                    <li className="grid g-col-12 ftvwebbooking__search-results-header">
                        <div className="g-col-2">
                            <strong>Datum</strong>
                        </div>
                        <div className="g-col-2">
                            <strong>Tid</strong>
                        </div>
                        <div className="g-col-3">
                            <strong>Klinik</strong>
                        </div>
                        <div className="g-col-3">
                            <strong>Behandlare</strong>
                        </div>
                    </li>
                    {wizardFormData.searchResult && wizardFormData.searchResult.length > 0 && wizardFormData.searchResult.slice(0, props.skip + 10).map(result => {
                        return (
                            <li className={props.relatedClinics.find(x => x.multiCheckboxItem.checkboxValue == result.healthcareFacilityId) ? "g-col-12 ftvwebbooking__search-results-wrapper--dark" : "g-col-12"} key={result.id}>
                                <div>
                                    <div className="g-col-12 ftvwebbooking__search-result--mobile">
                                        <div className="grid g-col-12">
                                            <div className="g-col-9">
                                                <span className="search-result-icon"
                                                    dangerouslySetInnerHTML={{ __html: props.calendarIcon }}
                                                ></span>
                                                {new Date(Date.parse(result.startTime)).getDate() + "/" + (new Date(Date.parse(result.startTime)).getMonth() + 1)},
                                                {' ' + new Date(Date.parse(result.startTime)).getHours() + ":" + ('0' + new Date(Date.parse(result.startTime)).getMinutes()).slice(-2)}<br />
                                                <span className="search-result-icon"
                                                    dangerouslySetInnerHTML={{ __html: props.placeAndPerformerIcon }}
                                                ></span>
                                                <a href={linkFacility(result)}>{displayNameFacility(result)}</a><br />
                                                <span className="performer">{result.performerName}</span>
                                            </div>
                                            <div className="g-col-3">
                                                <button className="button-primary" type="button" onClick={() => onClickSelectTimeSlot(result, result.performerName)}>Välj</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ftvwebbooking__search-result">
                                        <div className="grid g-col-12">
                                            <div className="g-col-2">
                                                {new Date(Date.parse(result.startTime)).getDate() + "/" + (new Date(Date.parse(result.startTime)).getMonth() + 1)}
                                            </div>
                                            <div className="g-col-2">
                                                {new Date(Date.parse(result.startTime)).getHours() + ":" + ('0' + new Date(Date.parse(result.startTime)).getMinutes()).slice(-2)}
                                            </div>
                                            <a href={linkFacility(result)} className="g-col-3">
                                                {displayNameFacility(result)}
                                            </a>
                                            <div className="g-col-3">
                                                {result.performerName}
                                            </div>
                                            <div className="g-col-2">
                                                <button className="button-primary" type="button" onClick={() => onClickSelectTimeSlot(result, result.performerName)}>Välj tid</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ftvwebbooking__divider--mobile">
                                </div>
                            </li>
                        )
                    })}
                    {props.isLoading &&
                        <li className="grid g-col-12 ftvwebbooking__search-results--loading"><span className="loader"></span></li>
                    }
                    {!props.isBookingWithCode && wizardFormData.selectedClinics.length == 0 &&
                        <li className="grid g-col-12 ftvwebbooking__search-results-wrapper ftvwebbooking__search-results-wrapper--dark">
                            <p className="g-col-12">
                                Välj först en klinik för att se tillgängliga tider.
                            </p>
                        </li>
                    }
                    {(wizardFormData.selectedClinics.length != 0 || wizardFormData.isBookingWithCode) && wizardFormData.searchResult === null &&
                        <li className="grid g-col-12 ftvwebbooking__search-results-wrapper ftvwebbooking__search-results-wrapper--dark">
                            <p className="g-col-12">
                                Tyvärr finns det inga lediga tider. För att få förslag på tider kan du testa att söka på: annat datum, annan tid på dagen, en annan klinik eller ett annat område.
                            </p>
                        </li>
                    }
                </ul>
                <div className="g-col-12 ftvwebbooking__divider">
                </div>
                <div className="g-col-12 ftvwebbooking__view-more-wrapper">
                    <button type="button" className="g-start-6" onClick={onClickGetMore}>Visa fler tider</button>
                </div>
            </div> : <div className="g-col-12 ftvwebbooking__search-results-wrapper"><h2>{props.errorHeader}</h2><div dangerouslySetInnerHTML={{ __html: props.errorMessage }}></div></div>}
        </>
    )
}