export const calculateWeeks = (request: WizardSearchRequest) => {
    const selectedDateTimeFrom = new Date(request.selectedDate).toLocaleString();
    const selectedDateTimeTo = new Date(new Date(request.selectedDate).setMonth(new Date(request.selectedDate).getMonth() + 3));

    const difference = selectedDateTimeTo.getTime() - new Date(selectedDateTimeFrom).getTime();
    const weeks = Math.ceil(difference / (7 * 24 * 60 * 60 * 1000));

    let currentWeekStart = new Date(selectedDateTimeFrom);
    let currentWeekEnd = new Date(currentWeekStart);
    currentWeekEnd.setDate(currentWeekEnd.getDate() + 6);

    return { currentWeekStart, currentWeekEnd, weeks };
}